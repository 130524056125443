import React, { Component } from "react";
import {
    Form,
    FormGroup,
    FormFeedback,
    Input,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";
import LoaderButton from "../components/LoaderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserLock,
    faCheckCircle,
    faTimesCircle,
    faUnlockAlt
} from "@fortawesome/pro-light-svg-icons";
import { Auth } from "aws-amplify";

import "../styles/containers/ChangePassword.css";

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            password: "",
            confirmPassword: "",
            isInvalid: false,
            isValid: false
        };
    }

    validateForm() {
        return (
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleKeyUp = () => {
        if (
            this.state.password.length > 0 &&
            this.state.confirmPassword.length > 0
        ) {
            this.state.password !== this.state.confirmPassword
                ? this.setState({ isInvalid: true })
                : this.setState({ isInvalid: false, isValid: true });
        }
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

       

        try{
            await Auth.completeNewPassword(
                this.props.getUserObject(),               
                this.state.password
                
            ).then(user => {
                this.setState({ success: true });
                this.props.setUserObject(user);
                

            this.props.userChangePassword(false);
            this.props.userHasAuthenticated(true);
            this.props.history.push("/");
            }).catch(e => {
              console.log(e);
            });
 
        } catch (e) {
            alert(e.message);
            this.setState({ isLoading: false });
        }
    };

    render() {      
        return (
            <div className="ChangePassword">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <Card>
                                <CardBody>
                                    <CardTitle className="h5">
                                        <FontAwesomeIcon
                                            icon={faUserLock}
                                            className="text-brand-red mr-3"
                                        />
                                        Change Password
                                    </CardTitle>
                                    <p>
                                    <ul class="list-unstyled">
                                        <li>
                                            <ul>
                                                <li>Minimum length, which must be at least 6 characters but fewer than 99 characters.</li>
                                                <li>Require numbers</li>
                                                <li>Require a special character from this set: ^ $ * . [ ] { } ( ) ? " ! @ #</li>
                                                <li>Require uppercase letters</li>
                                                <li>Require lowercase letters</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    </p>
                                    <Form onSubmit={this.handleSubmit}>
                                        <FormGroup>
                                          <Input
                                                valid={this.state.isValid}
                                                invalid={this.state.isInvalid}
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="New Password"
                                                autoFocus
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input
                                                valid={this.state.isValid}
                                                invalid={this.state.isInvalid}
                                                onKeyUp={this.handleKeyUp}
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                placeholder="Confirm Password"
                                                value={
                                                    this.state.confirmPassword
                                                }
                                                onChange={this.handleChange}
                                            />
                                            <FormFeedback
                                                valid
                                                className="mb-3"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                    className="mr-2"
                                                />
                                                Passwords match!
                                            </FormFeedback>
                                            <FormFeedback className="mb-3">
                                                <FontAwesomeIcon
                                                    icon={faTimesCircle}
                                                    className="mr-2"
                                                />
                                                Passwords don't match!
                                            </FormFeedback>
                                        </FormGroup>
                                        <LoaderButton
                                            icon={faUnlockAlt}
                                            block
                                            disabled={!this.validateForm()}
                                            type="submit"
                                            isLoading={this.state.isLoading}
                                            text="Change Password"
                                            loadingText="Changing password..."
                                            className="btn-brand-red"
                                        />
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
