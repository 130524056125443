export default {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_JFWTh5uaG",
        APP_CLIENT_ID: "1jgsqdnaplg43rk6qqj6h5u0u",
        IDENTITY_POOL_ID: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mcstatus",
        API_MC_STATUS: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mcstatus",
        API_MC_STATUS2: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mcstatus2",
        API_MC_HISTORY: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mchistory",
        API_MC_HISTORY2: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mchistory2",
        API_MC_TIMINGS: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mctimings",
        API_MC_ACCOUNT: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/account",
        API_MC_GROUP: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/group",
        API_MC_RELATION: "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/relation",
        API_MC_PARTS :  "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/mcpartreport",
        API_DASHBOARD_URL : "https://5ch4g1idv5.execute-api.us-east-1.amazonaws.com/prod/dashboardurl"
    }
};
