import React, { Component } from "react";
import { Button } from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSync, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import Chart from "react-google-charts";
import axios from "axios";
import config from "../config";
import moment from "moment";
import "react-dates/initialize";

import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";

import ComponentLoading from "../components/ComponentLoading";

import "../styles/containers/Reports.css";
import "react-dates/lib/css/_datepicker.css";
import { Auth } from 'aws-amplify';

export default class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            emptyResults: false,
            startDate: moment().subtract(1, "day"),
            endDate: moment(),
            machine_id: null,
            group: null,
            charts: {
                bar: {
                    data: []
                },
                pie: {
                    data: [],
                    colors: []
                },
                machine: "",
                parts: 0
            },
            account: {
                id: null,
                name: null,
                contract_end_date: null,
                groups: [],
                machines: []
            },
            selectOptions: [],
            selectedOption: 1
        };

        this.reloadPage = this.reloadPage.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount = () => {
        this.getChartData();
        this.getAccountData();
    };

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    getChartData = async () => {
        // Get the user object from the parent
        
        const session = await Auth.currentSession();
        let self = this;
        // Start building the query request string by parsing out the variables from
        // the query string in the browser
        let qs = require("query-string");
        let parsed = qs.parse(window.location.search);

        // Set defaults
        let startDate = this.state.startDate.toISOString();
        let endDate = this.state.endDate.toISOString();

        // Assign the startDate and endDate state vars (if they are set)
        if (parsed.startDate !== undefined && parsed.endDate !== undefined) {
            self.setState({
                startDate: moment(parsed.startDate, "YYYY-MM-DD"),
                endDate: moment(parsed.endDate, "YYYY-MM-DD")
            });

            startDate = parsed.startDate;
            endDate = parsed.endDate;
        }

        if (parsed.machine_id !== undefined) {
            self.setState({
                machine_id: parsed.machine_id
            });
        }

        if (parsed.group !== undefined) {
            self.setState({
                group: parsed.group
            });
        }

        // Build the query string
        let queryString = "?";
        queryString += "start_date=" + startDate + "&";
        queryString += "end_date=" + endDate + "&";

        if (parsed.machine_id !== undefined) {
            queryString += "machine_id=" + parsed.machine_id + "&";
        } else if (parsed.group !== undefined) {
            queryString += "group=" + parsed.group;
        } else if (parsed.machine_id === undefined && parsed.group === undefined) {
            queryString += "group=all_machines";
        }

        axios
            .get(config.cognito.API_MC_TIMINGS + queryString, {
                headers: {
                    Authorization: session.idToken.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                const results = response.data;

                // If the request has results
                if (Object.keys(results).length > 0) {
                    let chartData = [["State", "Minutes", { role: "style" }]];
                    let pieChartColors = [];

                    for (let key in results.results[0]) {
                        if (key !== "parts" && key !== "machine" && key !== "group") {
                            let arrIndex,
                                colorIndex = 0;
                            switch (key) {
                                case "cycle_time":
                                    arrIndex = 1;
                                    colorIndex = 0;
                                    break;
                                case "setup_time":
                                    arrIndex = 2;
                                    colorIndex = 1;
                                    break;
                                case "downtime":
                                    arrIndex = 3;
                                    colorIndex = 2;
                                    break;
                                case "idle_time":
                                    arrIndex = 4;
                                    colorIndex = 3;
                                    break;
                                default:
                                    arrIndex = 5;
                                    colorIndex = 4;
                            }

                            chartData.splice(arrIndex, 0, [
                                key,
                                parseInt(results.results[0][key]),
                                this.getChartColors(key)
                            ]);

                            pieChartColors.splice(colorIndex, 0, this.getChartColors(key));
                        }
                    }

                    self.setState({
                        isLoading: false,
                        emptyResults: false,
                        charts: {
                            bar: {
                                data: chartData
                            },
                            pie: {
                                data: chartData,
                                colors: pieChartColors
                            },
                            parts: results.results[0].parts,
                            machine:
                                results.results[0].machine !== undefined
                                    ? results.results[0].machine
                                    : results.results[0].group
                        }
                    });
                } else {
                    self.setState({
                        isLoading: false,
                        emptyResults: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    goBack = () => {
        this.props.history.goBack();
    };

    getBarChartOptions() {
        return {
            legend: {
                position: "none"
            },
            backgroundColor: "#343a40",
            chartArea: {
                left: 75,
                top: 0,
                bottom: 50,
                width: "100%",
                height: "100%"
            },
            tooltip: {
                showColorCode: false
            },
            hAxis: {
                minValue: 0
            }
        };
    }

    getPieChartOptions() {
        return {
            legend: {
                position: "none"
            },
            backgroundColor: "#343a40",
            pieSliceBorderColor: "#343a40",
            colors: this.state.charts.pie.colors,
            fontSize: 20,
            chartArea: {
                left: 50,
                top: 50,
                bottom: 50,
                right: 50,
                width: "100%",
                height: "100%"
            }
        };
    }

    getChartColors(state) {
        switch (state) {
            case "setup_time":
                return "yellow";
            case "idle_time":
                return "blue";
            case "downtime":
                return "#BF2A45";
            case "downtme":
                return "#BF2A45";
            case "cycle_time":
                return "#28A745";
            default:
                return "#37718E";
        }
    }

    reloadPage() {
        let startDate = this.state.startDate.toISOString();
        let endDate = this.state.endDate.toISOString();
        let machine = this.state.machine_id;
        let group = this.state.group;

        // Build the push state
        let pushState = "";
        pushState += "startDate=" + startDate + "&";
        pushState += "endDate=" + endDate + "&";
        pushState += machine !== null ? "machine_id=" + machine : "";
        pushState += group !== null ? "group=" + group : "";

        this.props.history.push({
            pathname: "/reports/",
            search: pushState
        });

        this.setState({
            isLoading: true
        });

        this.getChartData();
    }

    compilePresets() {
        let options = [];

        let groupOptions = this.state.account.groups.map(group => {
            return { value: group.id, label: group.name, type: "group" };
        });

        options.push({ label: "Groups", options: groupOptions });

        let machineOptions = this.state.account.machines.map(machine => {
            return {
                value: machine.machine_id,
                label: machine.machine_name,
                type: "machine"
            };
        });

        options.push({ label: "Machines", options: machineOptions });

        this.setState({
            selectOptions: options
        });
    }

    async getAccountData() {
       
        let self = this;
        const session = await Auth.currentSession();

        // Get the account data
        axios
            .get(config.cognito.API_MC_ACCOUNT, {
                headers: {
                    Authorization: session.idToken.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                const results = response.data.results[0];

                self.setState(
                    {
                        account: {
                            id: results.account_id,
                            name: results.account_name,
                            contract_end_date: results.contract_end_date,
                            groups: results.groups,
                            machines: results.machines
                        }
                    },
                    () => {
                        this.compilePresets();
                    }
                );
            });
    }

    handleChange(selected) {
        let machine,
            group = null;
        if (selected !== null) {
            machine = selected.type === "machine" ? selected.value : null;
            group = selected.type === "group" ? selected.value : null;
        } else {
            group = "all_machines";
            machine = null;
        }

        this.setState(
            {
                selectedOption: selected,
                group: group,
                machine_id: machine
            },
            () => {
                this.reloadPage();
            }
        );
    }

    formatGroupLabel = data => {
        const groupStyles = {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        };

        const groupBadgeStyles = {
            backgroundColor: "#EBECF0",
            borderRadius: "2em",
            color: "#172B4D",
            display: "inline-block",
            fontSize: 12,
            fontWeight: "normal",
            lineHeight: "1",
            minWidth: 1,
            padding: "0.16666666666667em 0.5em",
            textAlign: "center"
        };

        return (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );
    };

    ReportsHeader() {
        return (
            <div className="row h-100">
                <div className="col-md-6 my-auto text-center text-sm-left">
                    <div className="machine-name">
                        <FontAwesomeIcon icon={faClock} className="mr-3" />
                        Time Spent in State (Minutes) for{" "}
                        {this.state.charts.machine !== "" ? this.state.charts.machine : "[No data found]"}{" "}
                    </div>
                </div>
                <div className="col-md-3 my-auto">
                    <Select
                        isClearable
                        className="selectOptions"
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                        options={this.state.selectOptions}
                        placeholder={"All Machines"}
                        formatGroupLabel={this.formatGroupLabel}
                    />
                </div>
                <div className="col-md-3 text-center text-sm-right">
                    <DateRangePicker
                        startDateId="startDate"
                        endDateId="endDate"
                        noBorder
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={focusedInput => {
                            this.setState({ focusedInput });
                        }}
                        isOutsideRange={day => isInclusivelyBeforeDay(moment().add(1, "day"), day)}
                    />
                    <Button color="secondary" className="machine-button" onClick={this.reloadPage}>
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        if (this.state.emptyResults) {
            return (
                <React.Fragment>
                    {this.ReportsHeader()}
                    <div className="text-center mt-5">
                        <h3>
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
                            No results found for this data set, please try again.
                        </h3>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div className="Machine">
                {this.state.isLoading ? (
                    <ComponentLoading />
                ) : (
                    <React.Fragment>
                        {this.ReportsHeader()}
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <Chart
                                    width={"100%"}
                                    height={"500px"}
                                    chartType="BarChart"
                                    loader={<div>Loading Chart</div>}
                                    data={this.state.charts.bar.data}
                                    options={this.getBarChartOptions()}
                                />
                            </div>
                            <div className="col-md-6">
                                <Chart
                                    width={"100%"}
                                    height={"500px"}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={this.state.charts.pie.data}
                                    options={this.getPieChartOptions()}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
