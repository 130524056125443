import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClipboardCheck,
    faHeartRate,
    faChartPie,
    faBoxesAlt,
    faWarehouse,
    faExclamationCircle
} from "@fortawesome/pro-light-svg-icons";

export default class TabView extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    checkContractExpiry() {
        let rawContractEnd = this.props.childProps.getExpiryDate();
        let momentContract = moment(rawContractEnd, "MM/DD/YYYY");

        return momentContract.isBetween(moment(), moment().add(3, "months"));
    }

    render() {
        return (
            <div className="TabView">
                <div className="TabContainer">
                    <Link
                        to="/"
                        className={`tab-link ${
                            window.location.pathname === "/" ? "active" : ""
                        }`}
                    >
                        <FontAwesomeIcon
                            icon={faClipboardCheck}
                            className="mr-3"
                        />
                        Status
                    </Link>
                    <Link
                        to="/timeline"
                        className={`tab-link ${
                            window.location.pathname === "/timeline"
                                ? "active"
                                : ""
                        }`}
                    >
                        <FontAwesomeIcon icon={faHeartRate} className="mr-3" />
                        Timeline
                    </Link>
                    <Link
                        to="/reports"
                        className={`tab-link ${
                            window.location.pathname.indexOf("/report") > -1
                                ? "active"
                                : ""
                        }`}
                    >
                        <FontAwesomeIcon icon={faChartPie} className="mr-3" />
                        Reports
                    </Link>
                    <Link
                        to="/groups"
                        className={`tab-link ${
                            window.location.pathname === "/groups"
                                ? "active"
                                : ""
                        }`}
                    >
                        <FontAwesomeIcon icon={faBoxesAlt} className="mr-3" />
                        Groups
                    </Link>
                    <Link
                        to="/account"
                        className={`tab-link ${
                            window.location.pathname === "/account"
                                ? "active"
                                : ""
                        } ${this.checkContractExpiry() ? "expiring" : ""}`}
                    >
                        <FontAwesomeIcon icon={faWarehouse} className="mr-3" />
                        My Account
                        {this.checkContractExpiry() ? (
                            <span>
                                <span id="TooltipExample" className="ml-2">
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                    />
                                </span>
                                <Tooltip
                                    placement="bottom"
                                    isOpen={this.state.tooltipOpen}
                                    target="TooltipExample"
                                    toggle={this.toggle}
                                >
                                    Service Contract Expiring Soon
                                </Tooltip>
                            </span>
                        ) : (
                            ""
                        ) // print nothing if not expiring
                        }
                    </Link>
                </div>
            </div>
        );
    }
}
