import React, { Component } from "react";
import loading from "../img/componentLoading.svg";

export default class ComponentLoading extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="ComponentLoading">
                <div className="text-center py-5">
                    <img src={loading} alt="Component is loading..." />
                </div>
            </div>
        );
    }
}
