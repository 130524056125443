import React from "react";
import { Route, Switch } from "react-router-dom";

// import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Login from "./containers/Login";
import ChangePassword from "./containers/ChangePassword";
import Home from "./containers/Home";
import Reports from "./containers/Reports";
import Groups from "./containers/Groups";
import Timeline from "./containers/Timeline";
import Account from "./containers/Account";
import NotFound from "./containers/NotFound";
import ChangeOldPassword from "./containers/ChangeOldPassword"
import ReportsDashboard from "./containers/ReportsDashboard"

export default ({ childProps }) => (
    <Switch>
        <UnauthenticatedRoute
            path="/login"
            exact
            component={Login}
            props={childProps}
        />
        <UnauthenticatedRoute
            path="/change-password"
            exact
            component={ChangePassword}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/changepassword"
            exact
            component={ChangeOldPassword}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/"
            exact
            component={Home}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/reports"
            exact
            component={ReportsDashboard}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/utilreports/"
            exact
            component={Reports}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/reports/:uuid"
            exact
            component={ReportsDashboard}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/groups"
            exact
            component={Groups}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/timeline"
            exact
            component={Timeline}
            props={childProps}
        />
        <AuthenticatedRoute
            path="/account"
            exact
            component={Account}
            props={childProps}
        />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
    </Switch>
);
