import React from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-light-svg-icons";

export default ({
    isLoading,
    text,
    loadingText,
    className = "",
    disabled = false,
    icon,
    ...props
}) => (
    <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && (
            <FontAwesomeIcon icon={faSync} className="fa-spin mr-3" />
        )}
        {!isLoading && <FontAwesomeIcon icon={icon} className="mr-3" />}
        {!isLoading ? text : loadingText}
    </Button>
);
