import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";
import  { Auth } from 'aws-amplify';
import config from "../config.js";
import alertify from "alertifyjs";
import ComponentLoading from "../components/ComponentLoading.js";

import "../styles/containers/Groups.css";
import {
    faPlus,
    faTrash,
    faObjectGroup,
    faCogs,
    faInfoCircle,
    faPlusCircle,
    faTimesCircle
} from "@fortawesome/pro-light-svg-icons";

export default class Groups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            groups: [],
            machines: [],
            relations: [],
            modal: false,
            group_name: "",
            invalid: false,
            valid: false,
            group_created: false,
            dropdownOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    componentDidMount() {
        this.getGroupData();
    }

    async getGroupData() {
        
        const session = await Auth.currentSession();
        let self = this;

        // Get the account data
        axios
            .get(config.cognito.API_MC_ACCOUNT, {
                headers: {
                    Authorization: session.idToken.jwtToken,
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                const results = response.data.results[0];

                self.setState({
                    groups: results.groups,
                    machines: results.machines,
                    relations: results.relations,
                    isLoading: false
                });
            });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    async addGroup() {
       

        const session = await Auth.currentSession();

        if (this.state.group_name === "") {
            this.setState({
                invalid: true
            });
        } else {
            this.setState({
                invalid: false,
                valid: true
            });

            // Get the account data
            axios
                .post(
                    config.cognito.API_MC_GROUP,
                    {
                        group: this.state.group_name
                    },
                    {
                        headers: {
                            Authorization: session.idToken.jwtToken,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        this.toggle();
                        this.getGroupData();
                        alertify.success("Successfully created group");
                    } else {
                        alertify.error("Failed creating group");
                    }
                });
        }
    }

    async deleteGroup(group_id) {
        
        let self = this;
        const session = await Auth.currentSession();

        alertify.confirm(
            "Are you sure?",
            "This action <b>cannot</b> be undone.",
            function() {
                axios
                    .delete(
                        config.cognito.API_MC_GROUP + "?group_id=" + group_id,
                        {
                            headers: {
                                Authorization: session.idToken.jwtToken,
                                "Content-Type": "application/json"
                            }
                        }
                    )
                    .then(response => {
                        if (response.status === 200) {
                            self.getGroupData();
                            alertify.warning("Deleted group");
                        } else {
                            alertify.error("Failed deleting group");
                        }
                    });
            },
            function() {
                console.log("cancel the delete");
            }
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    printGroups() {
        let self = this;
        let groups = this.state.groups;

        let groupList = groups.map(function(group) {
            let machines = self.getRelation(group.id);
            let machinesList = machines.map(function(machine) {
                return (
                    <li key={machine.machine_id} className="list-group-item">
                        <div className="row">
                            <div className="col-md-12">
                                {machine.machine_name}
                            </div>
                        </div>
                    </li>
                );
            });
            return (
                <div key={group.id} className="col-md-4 mb-3">
                    <div className="card h-100">
                        <div className="card-header">
                            <FontAwesomeIcon
                                icon={faObjectGroup}
                                className="mr-2"
                            />
                            {group.name}
                        </div>
                        <div className="card-body pb-0">
                            <FontAwesomeIcon icon={faCogs} className="mr-2" />
                            Machines in this group:
                        </div>
                        <ul className="list-group list-group-flush">
                            {machinesList.length > 0 ? (
                                machinesList
                            ) : (
                                <li className="list-group-item text-muted">
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        className="mr-2"
                                    />
                                    No machines in found group
                                </li>
                            )}
                        </ul>
                        <div className="card-footer text-right">
                            <UncontrolledButtonDropdown
                                size="sm"
                                className="mr-2"
                            >
                                <DropdownToggle caret>
                                    Add Machine
                                </DropdownToggle>
                                {self.generateList(group)}
                            </UncontrolledButtonDropdown>
                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => self.deleteGroup(group.id)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="mr-2"
                                />
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            );
        });

        return <div className="row">{groupList}</div>;
    }

    generateList(group) {
        let self = this;
        let existingMachines = this.getRelation(group.id);
        let allMachines = this.state.machines;
        let relationsArray = this.state.relations.filter(function(relation) {
            return relation.group_id === group.id;
        });

        let machineList = allMachines.map(function(machine) {
            // Check if the machine is already in the relation
            let exists = existingMachines.some(
                item => item.machine_id === machine.machine_id
            );
            // Find the relation it belongs too (if it belongs to any)
            let relation_id = null;
            for (let i = 0; i < relationsArray.length; i++) {
                if (relationsArray[i].machine_id === machine.machine_id) {
                    relation_id = relationsArray[i].id;
                }
            }
            return (
                <DropdownItem
                    key={machine.machine_id}
                    onClick={() => {
                        self.toggleMachine(
                            exists,
                            relation_id,
                            group.id,
                            machine.machine_id
                        );
                    }}
                >
                    {exists ? (
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="mr-3 fa-fw text-danger"
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="mr-3 fa-fw text-success"
                        />
                    )}
                    {machine.machine_name}
                </DropdownItem>
            );
        });

        return <DropdownMenu>{machineList}</DropdownMenu>;
    }

    async toggleMachine(remove, relation_id, group_id, machine_id) {
        let user = this.props.getUserObject();
        let self = this;
        const session = await Auth.currentSession();

        // If remove is false, then we are adding a machine to a group
        if (!remove) {
            // Get the account data
            axios
                .post(
                    config.cognito.API_MC_RELATION,
                    {
                        group_id: group_id,
                        machine_id: machine_id
                    },
                    {
                        headers: {
                            Authorization: session.idToken.jwtToken,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        this.getGroupData();
                        alertify.success("Successfully added machine to group");
                    } else {
                        alertify.error("Failed adding machine to group");
                    }
                });
        } else {
            axios
                .delete(
                    config.cognito.API_MC_RELATION +
                        "?relation_id=" +
                        relation_id,
                    {
                        headers: {
                            Authorization: user.idToken.jwtToken,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        self.getGroupData();
                        alertify.warning("Removed machine from group");
                    } else {
                        alertify.error("Failed removing machine from group");
                    }
                });
        }
    }

    getRelation(group_id) {
        let relationsArray = this.state.relations.filter(function(relation) {
            return relation.group_id === group_id;
        });

        // Machine array
        let machines = [];

        for (let i = 0; i < relationsArray.length; i++) {
            machines.push(this.getMachines(relationsArray[i].machine_id));
        }

        return machines;
    }

    getMachines(machine_id) {
        return this.state.machines.find(el => el.machine_id === machine_id);
    }

    render() {
        return (
            <div className="Groups">
                {this.state.isLoading ? (
                    <ComponentLoading />
                ) : (
                    <React.Fragment>
                        <div className="mb-3">{this.printGroups()}</div>
                        <Button color="success" onClick={this.toggle}>
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Create Group
                        </Button>

                        <Modal
                            isOpen={this.state.modal}
                            toggle={this.toggle}
                            className={this.props.className}
                        >
                            <ModalHeader toggle={this.toggle}>
                                Create Group
                            </ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="exampleEmail">
                                            Group Name
                                        </Label>
                                        <Input
                                            type="text"
                                            name="group_name"
                                            id="group_name"
                                            placeholder="Enter a group name..."
                                            invalid={this.state.invalid}
                                            valid={this.state.valid}
                                            value={this.state.group_name}
                                            onChange={this.handleChange}
                                        />
                                        <FormFeedback>
                                            Group names cannot be blank.
                                        </FormFeedback>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="success"
                                    onClick={this.addGroup}
                                    className="mr-1"
                                >
                                    Create Group
                                </Button>
                                <Button color="secondary" onClick={this.toggle}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
